import { useContext } from "react";
import ALink from "~/components/features/alink"
import { LangContext } from "~/context/lang";

let Footer = () => {
    let { lang, getLang } = useContext(LangContext);
    let language = getLang('footer');

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-content">
                    <div className="row pb-3">
                        <div className="col-lg-3 col-sm-12">
                            <div className="footer-logo">
                                <img src="/images/logo.svg" width={190} alt="logo" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="footer-title">
                                {language.quickLinks}
                            </div>
                            <div className="footer-links">
                                <ALink href="/about-us">{language.aboutUs}</ALink>
                                <ALink href="/#projects">{language.ourProjects}</ALink>
                                <ALink href="/services">{language.ourServices}</ALink>
                                <ALink href="/#testimonies">{language.ourTestimonials}</ALink>
                                <ALink href="/safety">{language.safetyPrecautions}</ALink>
                                <ALink href="/faq">{language.faq}</ALink>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="footer-title">
                                {language.products}
                            </div>
                            <div className="footer-links">
                                <ALink href="/#our-products">{language.diesel}</ALink>
                                <ALink href="/#our-products">{language.petrol}</ALink>
                                <ALink href="/#our-products">{language.paraffin}</ALink>
                                <ALink href="/#our-products">{language.heavyFuel}</ALink>
                                <ALink href="/#our-products">{language.liquefiedPetrolium}</ALink>
                                <ALink href="/#our-products">{language.gasesMixtures}</ALink>
                                <ALink href="/#our-products">{language.jetFuel}</ALink>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="footer-title">
                                {language.customerService}
                            </div>
                            <div className="footer-links">
                                <ALink href="/contact-us">{language.onlineSupport}</ALink>
                            </div>
                            <div className="footer-links mt-2">
                                <div>
                                    <span className="footer-contact-link">T:</span>
                                    <ALink href="tel:+971504020229" dir="ltr">+971504020229</ALink>
                                    <br />
                                    <span className="footer-contact-link">T:</span>

                                    <ALink href="tel:+233500480300" dir="ltr">+233500480300</ALink>
                                </div>
                                <div>
                                    <span className="footer-contact-link">E:</span>
                                    <ALink href="mailto:info@coastal-petreolum.com">info@coastal-petreolum.com</ALink>
                                </div>
                                <div>
                                    <span className="footer-contact-link">E:</span>
                                    <ALink href="mailto:nagi@coastalcontructiong.com">nagi@coastalcontructiong.com</ALink>
                                </div>
                            </div>
                            <div className="footer-social-links">
                                <ALink href="https://facebook.com" target="_blank">
                                    <img src="/images/icons/facebook.svg" alt="facebook" />
                                </ALink>
                                <ALink href="https://x.com" target="_blank">
                                    <img src="/images/icons/x.svg" width={28} alt="x" />
                                </ALink>
                                <ALink href="https://instagram.com" target="_blank">
                                    <img src="/images/icons/instagram.svg" alt="instagram" />
                                </ALink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="footer-bottom-links">
                        <div className="d-flex align-items-center flex-wrap py-4" style={{ gap: "5rem" }}>
                            <ALink href="/about-us">{language.aboutUs}</ALink>
                            <ALink href="/#clients">{language.clients}</ALink>
                            <ALink href="/services">{language.ourServices}</ALink>
                            <ALink href="/contact-us">{language.contactUs}</ALink>
                            <ALink href="/jobs">{language.jobs}</ALink>
                        </div>
                    </div>

                </div>
            </div>
            <div className="footer-bottom-copy">
                <div className="container">
                    <div className="row footer-bottom-line">
                        <div className="col-lg-6">
                            <div className="copyright">
                                <p>{language.copyright} {new Date().getFullYear()} Coastal Petroleum</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-flex justify-content-lg-end gap-5">
                                <ALink href="/privacy">{language.privacy}</ALink>
                                <ALink href="/terms">{language.terms}</ALink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    )
}
export default Footer