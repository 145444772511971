import Axios from "axios";
import { useContext, useState } from "react";
import { isMobile } from "react-device-detect";
import Swal from "sweetalert2";
import { LangContext } from "~/context/lang";

let AskForService = ({ type = 'normal' }) => {
    let { getLang } = useContext(LangContext);
    let language = getLang('home');
    const [loading, setLoading] = useState(false);

    let askService = async (e) => {
        e.preventDefault();
        setLoading(true);
        let { name, phone, service } = e.target;
        let data = {
            name: name.value,
            phone: phone.value,
            service: service.value,
        };
        let response = await Axios.post('/ask-service/', data);
        if (response.data.status == 1) {
            Swal.fire({
                title: language.success,
                text: language.messageSent,
                icon: 'success',
            });
            document.getElementById('service-form').reset();
            setLoading(false);
        } else {
            Swal.fire({
                title: language.error,
                text: language.messageError,
                icon: 'error',
            });
            setLoading(false);
        }
    }


    const AskForm = () => {
        return (
            <form action="" method="post" id="service-form" style={{ position: "relative", zIndex: "100" }} onSubmit={askService}>
                <h3 className="fw-bold mb-3">{language.askForOffer}</h3>
                <input type="text" className="form-control" name="name" placeholder={language.yourName} required />
                <input type="text" className="form-control" name="phone" placeholder={language.phoneNumber} required />
                <input type="text" className="form-control" name="service" placeholder={language.service} required />
                <div className="text-center">
                    <button type="submit" className="btn btn-secondary" disabled={loading}>{language.getPhoneCall}</button>
                </div>
            </form>
        )
    }
    return (
        <div className="ask-for-service py-5" id="ask-for-service">
            <div className="container">
                {
                    type == 'normal' ?
                        <div className="ask-for-service-container">
                            <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div>
                                        <div className="section2-title">{language.askForService}</div>
                                        <div className="section2-header">{language.askForServiceTitle}</div>
                                        <div className="section2-text">{language.askForServiceText}</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12 position-relative">
                                    {
                                        isMobile ?
                                            ''
                                            :
                                            <img className="position-absolute" style={{ top: "-11rem", right: "-13rem" }} src="/images/ask-for-service-img-2.png" alt="ask-for-service-img-1" />
                                    }
                                    <AskForm />
                                    <img className="position-absolute" style={{ bottom: "-5rem", left: "-7rem" }} src="/images/ask-for-service-img-1.png" alt="ask-for-service-img-1" />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="ask-for-service-container h-100 p-0 border-0">

                            <div className="row">
                                <div className="col-lg-12 col-sm-12 text-center">
                                    <div>
                                        <div className="section2-title">{language.askForService}</div>
                                        <div className="section2-header " style={{ position: 'relative', zIndex: 1000 }}>{language.askForServiceTitle}</div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12 position-relative">
                                    {
                                        !isMobile ?
                                            <img className="position-absolute" style={{ top: "-18rem", right: "-1rem", zIndex: 10 }} src="/images/ask-for-service-img-2.png" alt="ask-for-service-img-1" />
                                            :
                                            ''
                                    }
                                    <AskForm />
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}
export default AskForService;