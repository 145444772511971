import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
	askModalShow: "SHOW_QUICKVIEW",
	hideQuick: "HIDE_QUICKVIEW",
	refreshStore: "REFRESH_STORE"
};

let initialState = {
	askModalShow: false,
	data: [],
};
const otherReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.showAskModal:
			return {
				...state,
				askModalShow: true,
			};

		case actionTypes.hideAskModal:
			return {
				...state,
				askModalShow: false,
			};
		case actionTypes.refreshStore:
			return {
				current: action.payload.current,
				askModalShow: false,
			};
		default:
			return state;
	}
};

export const actions = {
	refreshStore: (current) => ({
		type: actionTypes.refreshStore,
		payload: {
			current: current,
		},
	}),

	showAskModal: () => ({
		type: actionTypes.askModalShow,
	}),

	hideAskModal: () => ({
		type: actionTypes.hideAskModal,
	}),
};

const persistConfig = {
	keyPrefix: "coastol-",
	key: "other",
	storage,
};

export default persistReducer(persistConfig, otherReducer);
