import Layout from '../components/layout';
import Axios from 'axios';
import LangProvider from '../context/lang';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { wrapper } from "../store/index.js";

import 'bootstrap/scss/bootstrap.scss';
import "../public/scss/style.scss";

Axios.defaults.baseURL = process.env.API_URL;

const WrappedApp = ({ Component, ...rest }) => {
    const { store, props } = wrapper.useWrappedStore(rest);
    const { pageProps } = props;
    return (
        <Provider store={store}>
            <LangProvider>
                <PersistGate
                    persistor={store.__persistor}
                    loading={
                        <div className="loading-overlay">
                            <div className="bounce-loader">
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                            </div>
                        </div>
                    }>
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                </PersistGate>
            </LangProvider>
        </Provider >
    )
}
export default WrappedApp;

