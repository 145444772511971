import Header from "./partials/header/header";
import Footer from "./partials/footer/footer";

let Layout = ({ children }) => {
    return (
        <>
            <div className="page-wrapper">
                <Header adClass="bg-transparent home" />
                {children}
                <Footer />
            </div>
        </>
    )
}
export default Layout;