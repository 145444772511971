import { useContext, useEffect, useState } from "react";
import ALink from "~/components/features/alink"
import { LangContext } from "~/context/lang";
import { useRouter } from 'next/router';
import { Navbar, Nav } from 'react-bootstrap';
import AskServiceModal from "../ask-service-modal";
import { isMobile } from "react-device-detect";

let Header = () => {
    let { lang, getLang, setLang } = useContext(LangContext);
    let language = getLang('header');
    const [activeUrl, setActiveUrl] = useState('#hero');
    const [modalShow, setModalShow] = useState(false);

    let router = useRouter();
    let url = router.asPath;
    let { slug } = router.query;

    let toogleFixedHeader = () => {
        let header = document.querySelector('.header');
        let headerHeight = header.offsetHeight;
        let scrollTop = window.scrollY;
        if (scrollTop > headerHeight) {
            header.classList.add('fixed');
        } else {
            header.classList.remove('fixed');
        }
    }
    let goTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    let hideGoTop = () => {
        let goTop = document.querySelector('.go-top');
        // let conversations = document.querySelector('.recently-conversation');
        if (window.scrollY > 100) {
            goTop.classList.remove('d-none');
        } else {
            goTop.classList.add('d-none');
        }
    }



    useEffect(() => {
        window.addEventListener('scroll', hideGoTop);
    }, []);
    useEffect(() => {
        if (url.indexOf('404') <= 0) {
            window.addEventListener('scroll', toogleFixedHeader);
        }
    }, [url]);

    useEffect(() => {
        setActiveUrl(url);
    }, [url]);

    let closeNav = () => {
        setTimeout(() => {
            document.querySelector('.navbar-toggler').click();
        }, 200);
    }

    return (
        <>
            <header className={`header ${slug ? 'sub-page' : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="header-logo">
                                <ALink href="/" className="logo">
                                    <img src="images/logo.svg" width={130} className="coastal-logo" />
                                </ALink>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="header-nav">
                                <Navbar collapseOnSelect expand="sm" >
                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                    <Navbar.Collapse id="responsive-navbar-nav">
                                        <Nav className="mr-auto" activeKey={activeUrl}>
                                            <ALink className={`nav-link ${activeUrl == '/#hero' ? 'active' : ''}`} href="/#hero" onClick={() => closeNav()}>
                                                {language.home}
                                            </ALink>
                                            <ALink className={`nav-link ${activeUrl == '/about-us/' ? 'active' : ''}`} href="/about-us/" onClick={() => closeNav()}>{language.about}</ALink>
                                            <ALink className={`nav-link ${activeUrl == '/services/' ? 'active' : ''}`} href="/services/" onClick={() => closeNav()}>{language.services}</ALink>
                                            <ALink className={`nav-link ${activeUrl == '/jobs/' ? 'active' : ''}`} href="/jobs/" onClick={() => closeNav()}>{language.jobs}</ALink>
                                            <ALink className={`nav-link ${activeUrl == '/contact-us/' ? 'active' : ''}`} href="/contact-us/" onClick={() => closeNav()}>{language.contactUs}</ALink>
                                            {
                                                isMobile &&
                                                <>
                                                    <button type="button" className={"btn btn-secondary"} onClick={() => setModalShow(true)}>
                                                        {language.askForService}
                                                    </button>
                                                    <button type="button" aria-label="theme-btn" className={`change-language mx-2`} onClick={() => { setLang(lang == 'en' ? 'ar' : 'en') }}>
                                                        {/* <img src={`/images/${lang == 'en' ? 'ar' : 'en'}_icon.jpg`} width={24} alt="language selector" /> */}
                                                        {lang == 'en' ? 'عربي' : 'English'}
                                                    </button>
                                                </>
                                            }
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex align-items-center justify-content-end">
                            <div className="d-flex align-items-center mx-4 gap-5">
                                {
                                    !isMobile &&
                                    <>
                                        <button type="button" className={"btn btn-secondary"} onClick={() => setModalShow(true)}>
                                            {language.askForService}
                                        </button>
                                        <button type="button" aria-label="theme-btn" className={`change-language mx-2 text-dark`} onClick={() => { setLang(lang == 'en' ? 'ar' : 'en') }}>
                                            <img src={`/images/icons/${lang == 'en' ? 'ar' : 'en'}.png`} width={42} alt="language selector" />
                                            {/* {lang == 'en' ? 'عربي' : 'English'} */}
                                        </button>
                                    </>
                                }

                            </div>
                        </div>
                        <AskServiceModal show={modalShow} onHide={() => setModalShow(false)} />
                    </div>
                </div>
                <button type="button" className="go-top d-none" onClick={goTop}>
                    <img src="/images/icons/arrow_up.svg" width={24} alt="go-top" />
                </button>
            </header>
        </>

    )
}
export default Header