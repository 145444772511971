import Modal from 'react-bootstrap/Modal';
import AskForService from '../pages/home/ask-for-service';

let AskServiceModal = (props) => {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='ask-service-modal'
            dialogClassName="ask-modal-size"
        >
            <Modal.Header closeButton className='ask-service-modal-header'></Modal.Header>
            <Modal.Body
                className='ask-service-modal-body ask-for-service'
            >
                <AskForService type='modal' />
            </Modal.Body>
        </Modal>
    )
}

export default AskServiceModal;